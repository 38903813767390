<template>
  <resizeable-page>
    <template #header>
      <v-card-title>
        订单信息
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="查询"
          single-line
          hide-details
      ></v-text-field>
    </template>
    <template #toolbar>
    </template>
    <template #filter></template>
    <template #table="tableProps">
      <v-data-table
          :headers="headers"
          :items="contract"
          calculate-widths
          :items-per-page="limit"
          :page="page"
          item-key="_id"
          :search="search"
          dense
          fixed-header
          :height="tableProps.tableHeight"
          :server-items-length="orderCount"
          @update:page="getServiceOrder"
          :footer-props="footerOption"
      >
        <template #item.商品ID="{item}">
          <jd-product :sku-id="item.商品ID"></jd-product>
        </template>
      </v-data-table>
    </template>
  </resizeable-page>
</template>

<script>
import resizeablePage from "@/components/resizeable-page";
import db from "@/plugins/db";
import jdProduct from "@/views/contract/jdProduct";

export default {
  components:{
    resizeablePage,jdProduct
  },
  data() {
    return {
      headers: [
        {
          value: "订单号",
          text: "订单号",
          sortable: false,
          align: "start",
          width:100
        },
        {
          value: "商品ID",
          text: "服务sku",
          sortable: false,
          align: "start",
          width:100
        },
        {
          value: "商品名称",
          text: "服务sku名称",
          sortable: false,
          align: "start",
          width:150
        },
        {
          value: "订单状态",
          text: "订单状态",
          sortable: false,
          align: "start",
          width:150
        },
        {
          value: "下单帐号",
          text: "下单帐号",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "客户姓名",
          text: "客户姓名",
          sortable: false,
          align: "start",
          width:150
        },
        {
          value: "付款确认时间",
          text: "付款确认时间",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "订单完成时间",
          text: "订单完成时间",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "主商品订单号",
          text: "主商品订单号",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "主商品销售时间",
          text: "主商品销售时间",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "主商品完成时间",
          text: "主商品完成时间",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "主商品名称",
          text: "主商品名称",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "主商品京东价",
          text: "主商品京东价",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "服务状态",
          text: "服务状态",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "服务生效日期",
          text: "服务生效日期",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "服务截止日期",
          text: "服务截止日期",
          sortable: false,
          align: "start",
          width:200
        },
        {
          value: "店铺",
          text: "店铺",
          sortable: "FALSE",
          align: "start",
          width:100
        },
      ],
      contract: [],
      search: '',
      limit: 20,
      page: 1,
      orderCount: 0,
      footerOption: {
        'items-per-page-options': [5, 10, 15, 20, -1]
      }
    }
  },
  created() {
    this.getServiceOrder(1)
  },
  methods: {
    filterOnlyCapsText() {

    },
    getServiceOrder(page) {
      let limit = this.limit

      db.collection('orders')
          .count()
          .then((res) => {
            this.orderCount = res.total
          });
      db.collection('orders')
          .skip((page - 1) * limit)
          .limit(this.limit)
          .orderBy('_id', 'desc')
          .get()
          .then((res) => {
            this.contract = res.data
          });
    },
  }
}
</script>

<style scoped>

</style>